import { Injectable } from '@angular/core';
import { Sender } from 'src/app/maildata';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  PHP_API_SERVER = "./server/api.php";

  sendMail(sender: Sender): Observable<Sender>{
    return this.httpClient.post<Sender>(`${this.PHP_API_SERVER}`, sender)
  }

  // createPolicy(getstarted: Student): Observable<Student>{
  //   return this.httpClient.post<Studen t>(`${this.PHP_API_SERVER}/api/send.php`, getstarted);
  // }
  constructor(private httpClient: HttpClient) { }





}
