import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Sender } from 'src/app/maildata';
import { ApiService } from '../services/api.service';
// import { setInterval } from 'timers';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

opened = false;
showmodal = false;
  myFunction(){
      this.opened = true;
  }

  zero = false;

  // numimages = 8;
  // trans;
  // theta = 2 * Math.PI / this.numimages;
  // currImage = 0;
  constructor(private api: ApiService) { 

    // setInterval(()=>{
    //   this.currImage++;
    //   this.trans=`rotateY(${this.currImage * -this.theta}rad)`;
    //   if(this.currImage >= 100)
    //   this.currImage = 0;
    // },2000)
  }

  ngOnInit() {
    console.log(this.pRef)
  
    this.myParams = {
      particles: {
          number: {
              value: 800,
              "density": {
                "enable": true,
                "value_area": 4505
              }
          },
          color: {
              value: '#fff'
          },
          shape: 
            {
              "type": "circle",
              "stroke": {
                "width": 0,
                "color": "#000000"
              },
              "polygon": {
                "nb_sides": 5
              }
            },
            opacity: {
              "value": 0.5,
              "random": true,
              "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
              }
            },
            "size": {
              "value": 10,
              "random": true,
              "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
              }
            },
            "line_linked": {
              "enable": false,
              "distance": 500,
              "color": "#ffffff",
              "opacity": 0.4,
              "width": 2
            },
            "move": {
              "enable": true,
              "speed": 6,
              "direction": "bottom",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "bounce": false,
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            },
          },
          "interactivity": {
            "detect_on": "canvas",
            "events": {
              "onhover": {
                "enable": true,
                "mode": "grab"
              },
              "onclick": {
                "enable": true,
                "mode": "push"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 179.82017982017982,
                "line_linked": {
                  "opacity": 0.29947389720760836
                }
              },
              "bubble": {
                "distance": 400,
                "size": 4,
                "duration": 0.3,
                "opacity": 1,
                "speed": 3
              },
              "repulse": {
                "distance": 200,
                "duration": 0.4
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
    }
    };
    this.myStyle = {
      // 'position': 'absolute',
      'width': '100%',
      'height': '100%',
      // 'z-index': -1,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
      'background-color': '#0372cb'
  };
  }
  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;


  
  @ViewChild('head') pRef: ElementRef;
  @ViewChild('myModal') myModal;
  @HostListener("window:scroll", [])
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 100) {
      // console.log('You are 100px from the top to bottom');
      this.zero= true;
    } 
    else {
      this.zero = false;
    }
  }



  //Sending messages
  sender: Sender ={fullname: '', email: '', phone: '', description: ''}
  arr: any = [];
  error_name;
  error_email;
  error_phone;
  error_desc;
  sendmessage(){
    // console.log(this.sender);
    if(this.sender.fullname =='')
    this.error_name = "Name cannot be empty"
    else
    this.error_name = ''

    if(this.sender.email=='')
    this.error_email = "Email cannot be empty"
    else
    this.error_email = ''

    if(this.sender.phone=='')
    this.error_phone = "Phone number cannot be empty"
    else
    this.error_phone = ""

    if(this.sender.description =='')
    this.error_desc = "Message cannot be empty"
    else
    this.error_desc = ""
    // console.log(this.myModal)
    // this.myModal.nativeElement.className = 'modal fade show';
    // console.log(this.myModal)
    // this.showmodal = true;
    if(this.checkemail(this.sender.email) && this.sender.email != ''){
      // console.log(this.sender.phone.toString(), "KK")
      if(this.sender.fullname.length < 50 && this.sender.fullname.length > 0  && this.sender.email.length < 50 && this.sender.email.length > 0 && 
        this.sender.phone.toString().length < 20 && this.sender.phone.toString().length > 0 && this.sender.description.length > 0 && this.sender.description.length < 1001)
          {
            this.showmodal = true;
            console.log("HS")
            this.arr.push(this.sender);
            this.api.sendMail(this.arr).subscribe((mail: Sender)=>{
              // console.log("Sent", this.arr)
              // this.myModal.nativeElement.className = 'modal fade show'; 
              this.sender.fullname = "";
              this.sender.email = "";
              this.sender.phone = "";
              this.sender.description = "";
              
            })
            
          }

          else{
            alert("Please keep your inputs as short as possible")
          }
    }
    else{
      if(this.error_email != "Email cannot be empty")
        this.error_email = "Invalid Email"
    }
    
 
}

googlemaps(){
  window.open("https://www.google.com/maps/place/Xenfotech/@26.4868756,87.2739385,15z/data=!4m5!3m4!1s0x0:0xce2053cec954a588!8m2!3d26.4868756!4d87.2739385","_blank")
}
checkemail(mail){
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // console.log(mail, re.test(mail))
  return re.test(mail);
}
}
